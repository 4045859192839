import React, { FC } from 'react';

const StoryStreamSection: FC = () => {
  return (
    <>
      <div
        id="stry-wrapper"
        style={{ paddingBottom: '100px', margin: 'auto', maxWidth: '100%' }}
      ></div>
    </>
  );
};

export default StoryStreamSection;
